export const productList = {
  企业管理政务系统: {
    企业信息管理: {
      imgList: [],
      content: [
        {
          desc: "企业信息管理平台旨在建设一套统一的企业数据管理系统，实时动态掌握企业信息，优化经济运行分析监测，实现企业信息共享，该平台的使用成效体现在以下几个方面:",
          points: [
            "1.大大促进政府主管部门企业监控的时效性和精准性。",
            "2.为主管部门对企业经济运行监测进行分行业、分区域、分类别地进行纵向与横向、局部与整体的分析提供了方便快捷的渠道。",
            "3.为各级主管部门实现了企业信息共享，为各级部门开展相关工作提供了有效的企业信息支撑。",
            "4.提高了各级主管部门的服务效能，促进了政企互动。通过平台的政策法规发布、咨询服务，提升了政企之间的沟通效率，同时也为企业或行业提供了个性化、精细化的服务。",
            "5.通过系统整合，数据整合，减轻了企业数据重复申报的工作量。",
          ],
        },
        {
          desc: "系统功能设计模块主要包括：企业信息、政企互动、平台数据和系统设置四大块。",
          points: [
            "企业信息：信息录入、信息查询等作用。",
            "政企互动：通知公告，法律法规、工业政策的咨询服务。",
            "平台数据：上报统计、使用统计、数据分析。",
            "系统设置：用户管理，权限管理、系统管理和上传数据。",
          ],
        },
        {
          desc: "功能特色:",
          points: [
            "1、企业信息收集录入：涵盖企业基本情况、经济指标、用地、用电、创新指标、人力资源等各类报表，合计60多项指标。企业上报数据采取按月、按季度和实时变更上报三种方式。",
            "2、报表体系：分为企业、区县、市级三类报表，根据不同的权限，对相关企业数据进行统计汇总，并可以根据需求，自定义报表的指标数量。",
            "3、图表分析：使用可视化技术，自定义X、Y、Z三轴指标，通过系统分析生成各类图表，直观简洁地展示分析结果，分为区、市两级分析功能。",
            "4、辅助功能：包括企业上报情况统计查询、用户登录情况统计、权限分级、政企互动等。",
          ],
        },
      ],
    },
    工业亩产效益: {
      imgList: [
        require("@/assets/casus/jindong_1.png"),
        require("@/assets/casus/jindong_2.png"),
      ],
      content: [
        {
          desc: "本项目是首个跨部门、跨平台的信息系统，项目研发完成后，将整合政府11个部门的企业统计数据，在打破“数据堡垒“，推进数据共享方面有极大的创新性。基于大数据的信息分析处理应用，系统可入库统计企业数千家以上，通过大数据分析处理技术，实现海量的数据筛选、处理、查询；",
        },
        {
          desc: "通过对企业多层次、多维度的综合经济信息的分析，对工业经济运行情况、产值能耗情况、重点行业和产业发展情况、企业经济态势等进行监测，为在政策和措施上的快速引导和反应提供决策支持；同时推进要素资源差别化配置，引导企业转变发展理念、发展模式、投资方式，摆脱传统的领先物质资源消耗获得增长的路径依赖，树立依靠创新提升核心竞争力的全新价值追求，形成单位资源产出的最大化的发展导向，实现高质高效、绿色安全、可持续的发展，在推进资源要素配置改革、优化经济运行分析监测、实现企业信息共享、提高管理服务效能等方面起到良好的成效",
        },
        {
          desc: "企业综合评价管理系统由四大功能模块组成，分别是企业库管理模块、指标数据采集模块、评价管理模块和系统设置模块，系统涉及四类用户，分别是市级部门用户、区级部门用户、乡镇管理员和企业用户。各用户对各模块的使用权限、各用户的系统界面各有不同。系统管理员可以分配不同用户的权限。",
        },
        {
          desc: "系统评价管理流程设计:",
          points: [
            "①基础企业库调查",
            "②评价企业名单确定",
            "③指标数据收集",
            "④数据导入系统，取得预测评价结果",
            "⑤其它特殊情况的数据处理",
            "⑥公示",
            "⑦评价结果公布",
            "⑧差别化政策措施的执行",
          ],
        },
        {
          desc: "企业用户在规定时间内填报系统要求的信息，核对系统发布的数据；乡镇管理员填报系统要求的数据，核对系统下发的数据，审核企业上报的数据；区级部门管理员查看和审核企业数据和评价数据；市级部门用户上传采集数据，审核企业上报的数据，评价管理员进行企业评价，逐级对评价结果进行审核确认。",
        },
        {
          desc: "系统可以生成各级部门单位需要的报表。可以对报表进行导出到Excel处理。系统提供灵活的组合查询功能，以满足不同用户的查询需求。",
        },
        {
          desc: "系统于2017年上线，应用单位有：金华市经济和信息化局、兰溪市经济和信息化局，纳入系统管理的企业数量已超过1万家；",
        },
      ],
    },
  },
  园区公共服务平台: {
    园区党建: {
      imgList: [],
      content: [
        {
          desc: "园区党建工作，各党支部普遍存在流动党员多、不容易集聚的现状，采用党建+互联网的方式，采用APP，在线上把党员连接起来，通过网上党课、答题互动等多种形式，规范新领域党组织的党日活动，实现各类园区党建工作灵活便捷的管理模式，同时也打造园区职工了解园区重大活动、参与园区管理的平台；",
        },
        {
          desc: "整套APP包含后台管理（PC端）、管理端APP、用户端APP，可根据用户的政治面貌和管辖归属灵活配置菜单权限；",
        },
        {
          desc: "APP功能分为学习园地、党员之家、特色活动、先锋园地、通知公告、人才对接等内容模块，管理员后台实现系统管理、内容维护功能；",
          points: [
            "1、学习园地：可开放给所有注册用户进行在线学习，生成学习记录，并进行学习考核；",
            "2、党员之家：园区各级党支部的信息化管理功能，包括支部管理、党员管理、工作安排、活动会议、党员考评等内容；",
            "3、特色活动：园区特色活动组织、报名、活动开展，可通过图文、视频等形式生动鲜活地生成活动档案；",
            "4、先锋园地：园区各类先进评选展示，树立园区榜样；",
            "5、人才招聘：面向社会的人才招聘对接功能；",
            "6、通知公告",
          ],
        },
      ],
    },
    网络经济服务平台: {
      imgList: [
        "http://imagepub.chinau8.cn/ce32b510430f47bfa2e7d033e1002490?v=381172",
        "http://imagepub.chinau8.cn/01f63739500b4ebbaa441f1a83e33864?v=198557",
        "http://imagepub.chinau8.cn/cb6aa42540f3429c85092415dab6ab7b?v=902255",
      ],
      content: [
        {
          desc: "网络经济园区公共服务平台系统聚集电子商务领域，对接园区企业和政府公共服务中心，以大数据为引领，构建一站式办事、在线教育平台，集政策宣导、行业动态、电商学院、服务对接、服务展示、企业监测6大特色内容的综合性公共服务平台，系统于2017年底上线运行。",
        },
        {
          desc: "通过平台的建设，为主管部门提供了一个对外展示平台，良好地展示了园区建设、园区服务能力，为园区招商提供了便利。同时，作为一个统一的线上服务载体，能够将各种服务资源有机整合，办事中心，政策宣导，行业动态，政企互动等服务，大大削除了园区分散带来的管理不便问题，对提升政府服务水平有极大帮助。而平台的数据采集分析，又为主管部门监测企业发展趋势，优化产业结构调整提供良好地数据支撑。",
        },
        {
          desc: "从企业层面，一个统一的服务平台，满足了企业及时了解扶持政策，获取行业发展信息，一站式办事中心等现实需求，能够有效提升企业的办事效率。同时，通过企业服务产品和服务能力的展示，第三方服务商的引进，极大促进了企业信息交互，解决了一部分供需对接的需求，间接地促进了园区产业集聚和电子商务生态链建设。",
        },
        {
          desc: "  同时，平台的建设，通过开放式的电商学院，整合了线上线下培训资源，完善了园区各层次人才培养的模式，促进了从业人员的知识技能培养，提升了园区的人才储备。",
          imgs: [
            "http://imagepub.chinau8.cn/ce32b510430f47bfa2e7d033e1002490?v=381172",
            "http://imagepub.chinau8.cn/01f63739500b4ebbaa441f1a83e33864?v=198557",
            "http://imagepub.chinau8.cn/cb6aa42540f3429c85092415dab6ab7b?v=902255",
          ],
        },
        {
          desc: " 网络经济园区公共服务平台功能设计主要围绕信息流、服务流和政务流三个方面设计，主要功能分为7大块，分别是信息中心、电商学院、服务市场、数据上报、互动平台、企业数据库以及园区服务中心。其中，园区服务中心模块以线下服务方式实现，其余均为线上服务模块。各模块的主要功能如下： ",
          points: [
            "信息中心：主管部门各类信息发布，是园区对外的窗口。同时集成办事大厅功能，为企业各项事务办理提供指引和链接。",
            "电商学院：在线课程学习中心。",
            "服务市场：企业服务需求和服务产品的对接中心。",
            "数据上报：企业数据上报，运行监测分析功能的集成，为主管部门管理园区企业提供便利。",
            "互动平台：政企互动、企业咨询中心。",
            "企业数据库：园区企业向外部展示产品和服务能力的良好平台。",
            "园区服务中心：服务中心线下服务能力的集中展示。",
          ],
        },
        {
          desc: "平台用户根据使用需求，分为园区企业用户、外部服务商、主管部门、查询用户、管理员共5大类。各类用户对各模块的使用权限、各类用户的平台系统界面均有不同。管理员可以分配不同用户的权限。",
        },
        {
          desc: "该平台在整合电商资源，提高政府服务水平，促进企业信息交互，满足企业发展需求等方面起到重大作用，主要体现在以下几个方面：",
          points: [
            "1）、促进政策导向，提升政府服务水平。通过产业信息发布、政策培训、园区服务的方式，推动政策普及，传递市场信息，帮助企业排忧解难，扶持企业发展壮大。",
            "2）、搭建信息对接平台，助推企业发展。通过供需信息对接，引进第三方服务商的方式，为企业业务发展提供信息支持，拓展发展空间。通过线上线下培训课程的开展，促进企业人才梯队建设。",
            "3）、加强数据采集，梳理产业布局。通过企业",
          ],
        },
      ],
    },
  },
  社区服务管理系统: {
    社区管理系统: {
      imgList: [],
      content: [
        {
          desc: " 系统功能主要分为社区基本信息，社区人口管理，社区党建，社区组织，社区文体，社区治安，社会保障，民政工作等模块。通过各种操作方式，可以对各个板块进行录入，查看，修改，删除，搜索和基于各种条件的统计，汇总。同一套系统可以多人协同工作，可以专人设专门权限，专门板块，方便各级部门统筹规划，分配工作，并借此帮助各级社区进行数据统计和汇总分析。3D地图与房屋户籍信息相关联，使管理人员使用软件的时更直观更方便。同时还增加了即时通讯，方便各工作人员在工作上的联系和讨论。",
        },
        {
          desc: "核心功能:",
          points: [
            "1．户籍人口管理：对辖区内房屋进行登记，全面掌握房屋所有人，居住人员，房屋情况。以户籍为核心，对人口档案，家庭资料，人口变动等进行信息化关系，实现户籍人口管理的信息化，规范化。",

            "2．党建文体：以党组织为核心，对党员个人及组织进行管理。包括党员变动，党费缴纳，以及党组织会议，活动，培训等。对辖区内文化团体组织和活动进行登记管理，并对文体活动做相应记录。",
            "3．民政保障：对辖区内失业人员，低保家庭，残疾人员等进行汇总统计，方便相关政策的落实及特殊家庭人员的管理。",
          ],
        },
        {
          desc: "此外对社区组织，社区企业等单位都有完善的管理模块，实现对相关单位组织的监督管理。",
        },
        {
          desc: "目前，金华市多数社区已将天华社区管理系统实际运用到日常工作中，极大的方便了社区管理工作的进行，节省了大量人力，物力以及时间，创新了社区管理新模式，提升了社区管理工作效率，使社区管理步入了现代化信息管理时代。",
        },
        {
          desc: "金华洪湖路社区位于市中心地带，为老式社区，有一定的历史，住户面积较小，布局较为复杂。人口众多，以老年人为主，社区儿童年龄总体偏小，人群状况复杂，人口管理难度较大。社区事业机关，商业网点，公共设施较多，分布松散。",
        },
        {
          desc: "由于社区成立时间较早，存在着管理人员少，设施比较老旧，纸质档案文件存放时间过长等问题。近年来，随着社区人口的日益增多，信息处理也越来越频繁。随之而来的是管理工作日趋复杂繁重，需要耗费大量人力、物力，而社区一直以来使用传统人工的方式管理，这种管理方式存在着许多缺点,如:效率低、保密性差,另外时间一长,将产生大量的文件和数据,这对于查找、更新和维护都带来了不少的困难。这样的机制已经不能适应时代的发展，已愈发成为管理工作的一个瓶颈，已明显不能满足于社区的发展需要，实现社区的信息化管理迫在眉睫。",
        },
        {
          desc: "经过多次的考察与筛选，金华洪湖路社区最终选择了天华社区管理系统。目前天华社区管理系统已在洪湖路社区稳定运行，社区也完成了从传统管理方式到信息化管理的过度，成为了金华市推进新型社区建设的标杆。",
        },
        {
          desc: "此外新狮街道、东孝街道等街道下属的大部分社区都已经全面使用了天华社区管理系统。",
        },
      ],
    },
    社区党建管理系统: {
      imgList: [],
      content: [
        {
          desc: " 本系统通过网络化智慧化管理将社区以党建引领社会服务体系得到完善，社区的服务实现网络化、信息化、智能化，对社区党建管理实现精细化、扁平化。",
        },
        {
          desc: "主要实现的功能包括四大模块：",
          points: [
            "1、智慧党建：实现直观的社区党建展示系统，以3D实景效果图，以人机互动的形式，将社区每一名党员在社区的居住位置、服务特长、联系的居住户、管理的楼栋网格、参加公益服务情况、缴纳党费情况、参加组织生活情况、到户走访情况、党员政治生日等有个直观生动的展示。同时对社区内的孤寡老人、困难群众、需要上门帮扶的对象、居民微心愿有一个时时在线发送和接收的功能；",
            "2、智慧服务：对社区的服务功能有一个整体的职能网络覆盖。社区设置有八大中心即平安建设中心、志愿服务中心、教育培训中心、便民服务中心、文化活动中心、医疗服务中心、居家养老中心、应急联动中心，每一个中心都有一个网络化的自我管理系统，例如居家养老中心可以实现网络订餐送餐服务，老年人用餐打卡系统，某个老人两天没来了系统就会提示报警等；",
            "3、智慧小区：区域大党委的工作交流平台，党委下辖的各支部组织架构能在平台中实现电子架构，各支部的介绍和活动情况可以实现电子化存储，支部之间有工作交叉可以通过网络发布实现资源共享、共建、共促，形成组织联动、领域融合、资源管理、服务集成、服务集中；",
            "4、智慧商圈：与政府各大工作平台对接管理的系统，可以为社区居民实现业务的代办和指导，大多数居民足不出户即可享受到便捷的服务。",
          ],
        },
      ],
    },
  },
  在线考试系统: {
    在线考试系统: {
      imgList: [],
      content: [
        {
          desc: "考试系统的主要好处是一方面可以动态地管理各种考试信息，只要准备好知识面广且海量的题库，就可以按照要求自动生成各种试卷；另一方面，考试时间灵活，用户可以在规定时间段内的任意时间参加考试；另外计算机化的考试的最大特点是阅卷快，系统可以在考试结束时当场给出考试结果，计算机阅卷给了用户的最大的公平感。",
        },
        {
          desc: "系统于2013年上线，应用于浙江省商务厅国际服务外包综合考试，2014年改版应用于浙江省商务厅电子商务考试工作。",
        },
        { desc: "系统主要的功能模块包括：管理子系统和考试子系统" },
        {
          desc: "管理子系统：对试题库管理的部门较少，多为各系部，采用C/S模式，需要安装客户端，供系统管理员和试卷管理员登录，能够管理账户和题库，定义试卷和管理考试，并能够进行手工评卷；",
        },
        {
          desc: "考试子系统：考生数量较多，采用B/S模式，无需安装客户端，允许考生通过IE浏览器登录系统，参加考试、查询分数、完成作业；也允许试卷管理员通过浏览器登录系统，手工评卷和成绩查询。",
        },
        {
          desc: "系统能够实现以下功能：",
          points: [
            "(1)掌握本考试系统范围内所有用户的基本情况，包括编号，姓名，登录口令等。",
            "(2)基于考试系统的基本功能管理，本系统可以提供给管理员类用户及学生类用户使用。不同的用户类型所能完成的功能不同。教师类用户可以创建试题、删除过期试题、进行题库维护、创建试卷以及对本系统内人员信息进行维护和更新等；学生类用户主要是参与考试，完成试卷，查询成绩。",
            "(3)适时的试题题库更新，试题题型包括判断题、选择题类型。试题库的更新由管理员类用户完成。学生类用户不允许对试题进行更新。",
            "(4)试卷可由本系统自动生成，也可以由管理员类用户从题库中选择试题创建。",
          ],
        },
      ],
    },
  },
  办公自动化系统: {
    酒店办公自动化系统: {
      imgList: [],
      content: [
        {
          desc: "1．充分研究宾馆酒店办公业务，为宾馆酒店量身定制的办公平台解决方案。系统处理日常工作中各部门间的信息往来，如通知、工作联系单、备忘录，相关部门的工作日记，销售部的团队会议接待单发放和跟踪，各部门工作计划总结，宾馆文件发放，收文处理等，并有完整的电子档案系统。",
        },
        {
          desc: "2．完善的安全机制。系统对人员权限控制和分配进行严格的管理，管理员可根据需要设置操作人员的数据库权限，片区权限，设置不同文件类别的拟稿权限，设置文件的查看、修改、删除权限",
        },
        {
          desc: "3．突破地域限制，只要能上因特网就能随时随地开展工作，出差在外也能随时处理公司的办公事务。",
        },
        { desc: "4．系统具有良好的可扩展性及兼容性，灵活地进行模块扩展。" },
        {
          desc: "5．将日常管理纳入系统中，使难以协调的事务管理趋于理性、科学、轻松，系统在充分发挥计算机作用的情况下，保留传统办公的习惯，使系统更易使用。",
        },
      ],
    },
  },
  未来乡村系统: {
    北山口一颗金佛手: {
      imgList: [
        require("@/assets/product/beishankou_1.png"),
        require("@/assets/product/beishankou_2.png"),
      ],
      content: [
        { desc: "北山口一颗金佛手系统于2022年立项，预计于2023年正式上线。" },
        {
          desc: "作用：",
          points: [
            "1.首页动态化，操作简便；",
            "2.模块智能化，良好的展现村内历史文化、种植产业、所获荣誉等多方面信息；",
            "3.图表多元化，多种方式展现村内产业在各年度的种植、产量增减、销售等情况，便于村民与游客查看及了解；",
          ],
          imgs: [
            require("@/assets/product/beishankou_1.png"),
            require("@/assets/product/beishankou_2.png"),
          ],
        },
      ],
    },
    智治澧浦: {
      imgList: [
        require("@/assets/product/lipu_1.png"),
        require("@/assets/product/lipu_2.png"),
      ],
      content: [
        {
          desc: "“智治澧浦”项目，通过“两基础、一中心、N应用”的建设规划，围绕党政机关整体智治、数字政府、数字经济、数字社会、数字法治综合应用5个领域，建设智治澧浦数据展示中心、智治澧浦一体化平台、智治安监、矛盾纠纷调解、重点人员管控、智慧求助、垃圾分类、智慧养老、视频综合管理等24个子系统应用。",
        },
        {
          desc: "项目建成后，将使澧浦镇在硬件基础、业务应用和领导决策上有一个大的提升，最终达到多终端智能一体化、共享共治的建设目标。",
          imgs: [
            require("@/assets/product/lipu_1.png"),
            require("@/assets/product/lipu_2.png"),
          ],
        },
      ],
    },
    釜章乡村数字化: {
      imgList: [
        require("@/assets/product/fuzhang_1.png"),
        require("@/assets/product/fuzhang_2.png"),
      ],
      content: [
        {
          desc: "金华市金东区岭下镇釜章村“乡村数字化”项目围绕“乡村治理”“乡村服务”“产业共富”的目标，以文旅产业融合发展、乡村治理服务同步发展、绿色农业智慧发展为主线，以推进未来乡村建设试点为着动力，全面推动釜章的产业振兴、文化振兴、生态振兴、农业振兴，高标准、高水平、高质量建成现代化“未来乡村”，努力打造“产业兴、环境美、生活好、服务佳、治理强”的生产生态生活服务治理融合发展的未来乡村浙江样板。",
        },
        {
          desc: "本次建设的釜章村数字化建设以省农业农村局“一统三化九场景”为指导思想，因地制宜，制定了以文旅场景为特色，利用虚拟导览、各类数字化硬件，丰富文旅、产业、服务等应用场景，打造具有釜章特色的应用，本次建设内容包含基础文旅服务系统、未来产业场景信息平台系统，以及针对统建平台各类数据协助更新数据、推广应用，同时对接“2+4”的智慧医疗、社区文化、智慧学习、智慧救助、智慧养老的场景应用，丰富“我的家园”中釜章村的应用内容。",
          imgs: [
            require("@/assets/product/fuzhang_1.png"),
            require("@/assets/product/fuzhang_2.png"),
          ],
        },
      ],
    },
    东叶乡村数字化: {
      imgList: [
        require("@/assets/product/dongye_1.png"),
        require("@/assets/product/dongye_2.png"),
      ],
      content: [
        {
          desc: "金华市金东区源东乡东叶乡村数字化建设项目以省农业农村局“一统三化九场景”为指导思想，以光南故里为核心，以文旅产业融合发展、乡村治理服务同步发展、绿色农业智慧发展为主线，以推进未来乡村建设试点为着力点，围绕“乡村治理”“乡村服务”“产业共富”的目标，制定了包含文旅、产业、风貌等应用场景的具有东叶特色的应用。",
        },
        {
          desc: "本次建设内容包含基础文旅服务系统、未来产业场景信息平台系统，以及针对统建平台各类数据协助更新数据、推广应用，丰富“我的家园”中东叶村的应用内容。",
          imgs: [
            require("@/assets/product/dongye_1.png"),
            require("@/assets/product/dongye_2.png"),
          ],
        },
      ],
    },
  },
};
