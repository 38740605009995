<template>
  <div>
    <div class="index1">
      <div class="box1-title">产品中心</div>
      <div class="border"></div>
      <div class="text">
        金华市天华网络科技有限公司成立于2007年，公司主要从事信息行业应用软件开发和技术服务，基于移动互联网与物联网、大数据和云计算<br />
        等技术，开发大数据管理服务云平台。
      </div>
    </div>
    <div class="index2" ref="box">
      <div class="head">
        <div class="breadcrumb_box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>{{ category }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ systemName }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <el-button type="primary" @click="goBack()">返回上一级</el-button>
      </div>
      <div class="img_row" v-if="data.imgList.length > 0">
        <img :src="item" v-for="item in data.imgList" />
      </div>
      <div v-if="data" class="content_box">
        <div v-for="item in data.content">
          <div class="desc_row">{{ item.desc }}</div>
          <div v-for="point in item.points" class="point_row">
            <img src="../../assets/homepage/round.png" />{{ point }}
          </div>
          <!-- <img v-for="img in item.imgs" :src="img" /> -->
        </div>
      </div>

      <!-- <div class="desc_row">{{ data.desc }}</div>
      <div v-for="item in data.point" class="point_row">
        <img src="../../assets/homepage/round.png" />{{ item }}
      </div>
      <div class="desc_row">{{ data.foot }}</div> -->
    </div>
  </div>
</template>

<script>
import { productList } from "../../utils/product.js";
export default {
  name: "productDetail",
  data() {
    return {
      id: null,
      category: null,
      systemName: null,
      data: {},
    };
  },

  mounted() {
    this.category = this.$route.params.title;
    this.systemName = this.$route.params.desc;
    this.data = productList[this.category][this.systemName];
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.index1 {
  height: 365px;
  background: url("~@/assets/product/banner.png") no-repeat center center;
  background-size: auto 100%;
  text-align: left;
  padding-left: 340px;
}
.box1-title {
  font-size: 28px;
  font-weight: bold;
  color: rgba(52, 111, 255, 1);
  padding-top: 139px;
  margin-bottom: 16px;
}
.border {
  width: 100px;
  height: 10px;
  line-height: 20px;
  border-radius: 10px;
  background-color: rgba(52, 111, 255, 1);
  margin-bottom: 16px;
}

.text {
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  line-height: 20px;
}

.index2 {
  margin: 0 auto;
  margin-top: -40px;
  width: 1380px;
  // height: 605px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  box-shadow: 0px 0px 10px 0px rgba(52, 111, 255, 0.3);
  // background-image: url(../../assets/product/product.png);
  background-size: 962px 465px;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  .head {
    // margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .img_row {
    img {
      width: 320px;
      height: 180px;
      margin-right: 30px;
      margin-top: 20px;
      float: left;
    }
  }
  .desc_row {
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
    text-align: left;
    margin-top: 20px;
    // &:last-child {
    //   margin-top: 10px;
    // }
  }
  .point_row {
    text-align: left;
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
    text-align: left;
    margin-top: 10px;
    img {
      width: 7px;
      height: 7px;
      margin-right: 8px;
    }
  }
}
.content_box {
  img {
    margin-top: 10px;
    max-width: 1380px;
  }
}
::v-deep .el-breadcrumb__item {
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  text-align: left;

  &:last-child .el-breadcrumb__inner {
    color: rgba(52, 111, 255, 1);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>
